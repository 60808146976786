import { ENV } from 'config/ENV.config'

export const setWindowTitle = (title: string = null) => {
  const t = title || window.location.pathname?.split('/')?.slice(1)?.join(' - ')
  document.title = t.charAt(0).toUpperCase() + t.slice(1)
}

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export const getUrlFromJson = (jsonStr) => {
  if (jsonStr) {
    return ENV.apiEndpoint + '/' + JSON.parse(jsonStr).url
  }
  return ''
}

export const monthOptions = [
  { name: 'January', value: '01' },
  { name: 'February', value: '02' },
  { name: 'March', value: '03' },
  { name: 'April', value: '04' },
  { name: 'May', value: '05' },
  { name: 'June', value: '06' },
  { name: 'July', value: '07' },
  { name: 'August', value: '08' },
  { name: 'September', value: '09' },
  { name: 'October', value: '10' },
  { name: 'November', value: '11' },
  { name: 'December', value: '12' },
]

export const monthShortOptions = [
  { name: 'Jan', value: '01' },
  { name: 'Feb', value: '02' },
  { name: 'Mar', value: '03' },
  { name: 'Apr', value: '04' },
  { name: 'May', value: '05' },
  { name: 'Jun', value: '06' },
  { name: 'Jul', value: '07' },
  { name: 'Aug', value: '08' },
  { name: 'Sep', value: '09' },
  { name: 'Oct', value: '10' },
  { name: 'Nov', value: '11' },
  { name: 'Dec', value: '12' },
]

export const getUrlFromJsonArray = (jsonArrStr) => {
  if (jsonArrStr) {
    return ENV.apiEndpoint + '/' + JSON.parse(jsonArrStr)[0].url
  }
  return ''
}
