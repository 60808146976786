import { COMPANY_ID, USER_INFO, USER_TOKEN } from "@constants/auth.constant";
import { LocalStorageService } from "@services/utils/localStorage.service";
import axios from "axios";
import { ENV } from "./ENV.config";

const instance = axios.create({
	baseURL: ENV.apiEndpoint,
	headers: {
		Accept: 'application/json',
	},
})

instance.interceptors.request.use(
	(config: any) => {
		const accessToken = LocalStorageService.get(USER_TOKEN) || null;
		const uid = LocalStorageService.get(USER_INFO)?.userId || null;
		const cid = LocalStorageService.get(COMPANY_ID) || null;

		if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
		if (uid) config.headers.uid = uid;
		if (cid) config.headers.cid = cid;
		return config;
	},
	(error) => {
		if (error.response) {
			return Promise.reject({
				...error.response.data,
				...{ code: error.response.data.code || error.status },
			})
		}

		return Promise.reject({
			body: false,
			code: 404,
			msg: 'Server not responding',
		})
	}
);

instance.interceptors.response.use(
	(res: any) => {
		if (res.data.code === 200) return { ...res.data }

		if (res.data.code === 401) {
			LocalStorageService.clear()
			window.location.reload()
		}
		return Promise.reject({
			body: res.data.body,
			code: res.data.code,
			msg: res.data.msg,
		})
	},
	(error) => {
		if (error?.response) {
			if (error?.response?.data?.code === 401) {
				LocalStorageService.clear()
				window.location.reload()
			}

			if (error?.response?.data?.error) {
				return Promise.reject({
					code: error?.response?.data?.code,
					msg: error?.response?.data?.error,
					body: {},
				})
			}

			return Promise.reject({
				...error?.response?.data,
				...{ code: error.response.data.code || error.status },
			})
		} else {
			return Promise.reject({
				code: 500,
				msg: 'Server not responding',
				body: {},
			})
		}
	}
)

// const refreshToken = () => {
//   return instance.post("/auth/refresh-token");
// };
// const setToken = (accessToken: string) => {
//   LocalStorageService.set("accessToken", accessToken);
// };
// const setRefreshToken = (refreshToken: string) => {
//   LocalStorageService.set("refreshToken", refreshToken);
// };

export const axiosIns = instance;
