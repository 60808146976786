import Button from "@components/Button";
import { Input } from "@components/Input";
import { SIGNUP, VERIFY_OTP } from "@constants/internal-route.constant";
import { AuthService } from "@services/api/Auth.service";
import { ToastService } from "@services/utils/toast.service";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

export default function ForgetPassword() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = (formData) => {
    setIsLoading(true);
    AuthService.forgetPassword(formData)
      .then((res) => {
        console.log("res", res);
        navigate(VERIFY_OTP, {
          state: { token: res.body?.tempToken },
        });
      })
      .catch((err) => ToastService.error(err?.msg))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <div className="feature-area">
        {/* <img src={forgetImg} alt="" className="img-fluid forgate-img" /> */}
        <h4 className="form-title">Forgot Password</h4>
        <p>Enter your username or email address and we will send you a link to reset your password.</p>
      </div>
      <form className="form-area" onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Enter your email"
          registerProperty={{
            ...register("username", { required: "Please Enter username" }),
          }}
          isError={!!errors?.username}
          errorMessage={errors?.username?.message as string}
        />
        <div>
          <Button
            className="w-100"
            color="primary"
            type="submit"
            size="lg"
            isLoading={isLoading}
            loadingText="Reseting..."
          >
            Reset Password
          </Button>
        </div>
        <div className="new-user">
          <h5>
            New User? <Link to={SIGNUP}>Sign Up Now</Link>
          </h5>
        </div>

        {/* <Button className="cust-btn common-btn" type="submit" isDisabled={isLoading}>
          {isLoading ? <ButtonLoader /> : "Reset Password"}
        </Button> */}
      </form>
    </>
  );
}
