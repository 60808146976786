import { APP_KEY, COMPANY_ID, NEXT } from "@constants/auth.constant";
import { AuthService } from "@services/api/Auth.service";
import { LocalStorageService } from "@services/utils/localStorage.service";
import { SessionStorageService } from "@services/utils/sessionStorage.service";
import { ToastService } from "@services/utils/toast.service";

export const appLoginHelper = () => {
	const appKey = SessionStorageService.get(APP_KEY) || null;
	const next = SessionStorageService.get(NEXT) || null;
	const companyId = LocalStorageService.get(COMPANY_ID) || null;
	if (appKey && next) {
		const reqBody = { appKey, companyId };
		AuthService.appLogin(reqBody)
			.then((resp) => {
				if (resp.code === 200) {
					const url = `${next}?uid=${resp?.body?.userId}&appToken=${resp?.body?.accessToken}&cid=${companyId}`;
					window.open(url, "_self");
				} else if (resp.code === 503) {
					window.open(`${window.location.origin}+"/invoice-list"`, "_self");
				}

			})
			.catch((err) => ToastService.error(err?.msg));
	}
};
