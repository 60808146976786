import Footer from "@components/Footer/Footer";
import { ENV } from "config/ENV.config";
import Header from "pages/Header";
import { Outlet } from "react-router-dom";
import loginImg from "../assets/image/login-img.png";

const AuthLayout = () => {
  const productCode = ENV.productCode || "";

  return (
    <div>
      <Header />

      <div className="container container-wrap">
        <div className="row">
          <div className="col-md-5">
            {productCode == "HRBOI" ? (
              <h4 className="heading-title">
                Manage and Track your Employees Activities
              </h4>
            ) : (
              <h4 className="heading-title">
                Entire Business Automation Software
              </h4>
            )}
            <img src={loginImg} alt="" className="login-img" />

            <div className="benifit-area">
              <h4>Key points/features</h4>

              <p>User friendly system</p>
              <p>Instant Provisioning</p>
              <p>No user limitation</p>
              <p>Pay-as-you-Go</p>
              <p>Daily/weekly basis data backup</p>
              <p>Data is highly secured</p>
              <p>Total system is hosting on Cloud</p>
            </div>
          </div>
          <div className="col-md-7">
            <div className="cust-card ">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default AuthLayout;
