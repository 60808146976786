import { APP_KEY, NEXT } from "@constants/auth.constant";
import { LOGIN } from "@constants/internal-route.constant";
import { useAuth } from "@context/Auth";

import { appLoginHelper } from "@services/helper/app.helper";
import { SessionStorageService } from "@services/utils/sessionStorage.service";
import { useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

const AppLogin = () => {
	const { isAuthenticated } = useAuth();
	const [searchParams] = useSearchParams();

	const appKey = searchParams.get(APP_KEY);
	const next = searchParams.get(NEXT);

	useEffect(() => {
		SessionStorageService.set(APP_KEY, appKey);
		SessionStorageService.set(NEXT, next);
		if (isAuthenticated && next && appKey) {
			appLoginHelper();
		}
	}, [isAuthenticated, next, appKey]);

	if (!isAuthenticated) return <Navigate to={LOGIN} state={{ isAppLogin: true }} />;

	if (!next || !appKey) return <Navigate to={"/"} />;

	return (
		<div

		>
			<p>
				Please wait...
			</p>
		</div>
	);
};

export default AppLogin;
