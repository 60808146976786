import Button from "@components/Button";
import { Input } from "@components/Input";
import { Select } from "@components/Select";
import { LOGIN } from "@constants/internal-route.constant";
import { AuthService } from "@services/api/Auth.service";
import { ToastService } from "@services/utils/toast.service";
import { ENV } from "config/ENV.config";
import { G_CAPTCHA_V3_SITE_KEY } from "config/constants";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

declare const window: any;

const SignUp = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [regSuccess, setRegSuccess] = useState<boolean>(false);
  const [countries, setCountries] = useState<[]>([])
  const [packages, setPackages] = useState<[]>([])
  const [captchaToken, setCaptchaToken] = useState<any>('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const productCode = ENV.productCode;


    AuthService.getPackages(productCode).then((res) => {
      setPackages(res?.body)
    })
      .catch((err) => console.log(err?.msg))
      .finally(() => {
        setTimeout(() => {
          loadGoogleReCaptchaV3();
        }, 2500);

        setIsLoading(false)
      });

    AuthService.getCountries().then((res) => {
      setCountries(res?.body)
    })
      .catch((err) => console.log(err?.msg))
      .finally(() => setIsLoading(false));

  }, [])

  const onSubmit = (fomrData) => {

    if (!captchaToken) {
      ToastService.warning("reCaptcha is not valid");
    }

    setIsLoading(true);

    fomrData["productCode"] = ENV.productCode;
    fomrData["captchaValue"] = captchaToken;

    AuthService.signUp(fomrData)
      .then((resp) => {
        ToastService.success(resp?.msg);
        setRegSuccess(true);
      })
      .catch((err) => ToastService.error(err?.msg))
      .finally(() => {
        setIsLoading(false)
        refreshReCaptchaToken();
      });

  };

  const loadGoogleReCaptchaV3 = () => {
    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.google.com/recaptcha/api.js?render=${G_CAPTCHA_V3_SITE_KEY}`
    script.addEventListener('load', () => {
      refreshReCaptchaToken();
    })
    document.body.appendChild(script)
  }

  const refreshReCaptchaToken = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(G_CAPTCHA_V3_SITE_KEY, { action: 'register' }).then((token: string) => {
        //localStorage.setItem(RECAPTCHA_TOKEN_KEY, token)
        setCaptchaToken(token);
      })
    })
  }


  return (
    <div className="signup-form">
      <h4 className="form-title">Sign Up to Your Account</h4>
      {regSuccess ? (
        <div className="alert alert-success" role="alert">
          Your registration has been completed successfully. <b>Please check your email to verify your account. It may take maximum 10 minutes</b> to the email.
        </div>
      ) : (<></>)}
      <form noValidate onSubmit={handleSubmit(onSubmit)}>

        <div className="row">
          <div className="col-md-12">
            <Input
              label="Business Name"
              registerProperty={{
                ...register("bizName", { required: "Please provide a business name" }),
              }}
              isError={!!errors?.bizName}
              errorMessage={errors?.bizName?.message as string}
            />
          </div>
          <div className="col-md-12">
            <Input
              label="Full Name"
              registerProperty={{
                ...register("fullName", { required: "Name is required" }),
              }}
              isError={!!errors?.fullName}
              errorMessage={errors?.fullName?.message as string}
            />
          </div>
          <div className="col-md-12">
            <Input
              label="Email Address"
              registerProperty={{
                ...register("email", { required: "Provide an email" }),
              }}
              isError={!!errors?.email}
              errorMessage={errors?.email?.message as string}
            />
          </div>
          <div className="col-md-12">
            <Input
              label="Phone Number"
              type="number"
              registerProperty={{
                ...register("mobile", { required: "Please provide phone number" }),
              }}
              isError={!!errors?.mobile}
              errorMessage={errors?.mobile?.message as string}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <Input
              label="Password"
              type="password"
              registerProperty={{
                ...register("pwdKey", { required: "Please provide a strong password" }),
              }}
              isError={!!errors?.pwdKey}
              errorMessage={errors?.pwdKey?.message as string}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <Input
              label="Conf. Password"
              type="password"
              registerProperty={{
                ...register("confPass", { required: "Confirm password is missing" }),
              }}
              isError={!!errors?.confPass}
              errorMessage={errors?.confPass?.message as string}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <Select
              label='Country'
              isRequired
              options={countries}
              textKey='title'
              valueKey='id'
              registerProperty={{
                ...register('countryId', { required: 'Country is required' }),
              }}
              isError={!!errors?.countryId}
              errorMessage={errors?.countryId?.message as string}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <Select
              label='Package'
              isRequired
              options={packages}
              textKey='packageTitle'
              valueKey='id'
              textKeySecond="price"
              textKeyThird="cycleName"
              registerProperty={{
                ...register('packageId', { required: 'Package is required' }),
              }}
              isError={!!errors?.packageId}
              errorMessage={errors?.packageId?.message as string}
            />
          </div>
        </div>

        <div className="mt-4">
          <Button
            className="w-100"
            color="primary"
            type="submit"
            size="lg"
            isDisabled={isLoading}
            loadingText="Signup..."
          >
            Sign Up
          </Button>
        </div>

        <div className="new-user">
          <h5>
            Already have an account? <Link to={LOGIN}>Sign In Now</Link>
          </h5>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
