import { FC, useEffect } from 'react'
import topbar from 'topbar'

const pColor = '#0275d8'
const sColor = '#5cb85c'

interface ITop {
  hide: () => void
  show: () => void
}

const config = {
  barColors: {
    '0': pColor,
    '1': sColor,
  },
  barThickness: 2,
  shadowBlur: 5,
}

topbar.config(config)
const topProgress: ITop = topbar

const TopProgressCom: FC = () => {
  useEffect(() => {
    topProgress.show()
    return () => topProgress.hide()
  }, [])

  return <></>
}

export { TopProgressCom, topProgress }

