import { AuthProvider } from '@context/Auth';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { createRoot } from "react-dom/client";
import { ToastContainer } from 'react-toastify';
import App from "./App";
import "./assets/css/scss/style.scss";
import reportWebVitals from "./reportWebVitals";


const queryClient = new QueryClient();

const container = document.getElementById("root") as HTMLElement;

createRoot(container).render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<AuthProvider>
				<App />
			</AuthProvider>
			<ToastContainer />
		</QueryClientProvider>
	</React.StrictMode>
);

reportWebVitals();
