import Button from "@components/Button";
import { SIGNUP } from "@constants/internal-route.constant";
import { useAuth } from "@context/Auth";
import { ENV } from "config/ENV.config";
import { Navigate, useNavigate } from "react-router-dom";

export default function Home() {
  const { isAuthenticated, currentUser } = useAuth();
  const navigate = useNavigate();

  const productCode = ENV.productCode || "";

  const gotoConsole = (type) => {
    if (isAuthenticated) {
      let url = "https://" + type.toLowerCase() + ".erpboi.com";
      window.open(url, "_self");
    } else {
      console.log("ok");
      return <Navigate to={SIGNUP} />;
    }
  };

  return (
    <div className="home-wrapper">
      {productCode == "HRBOI" ? (
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="card-group">
                <div className="card">
                  {/* <img src="/jd.png" className="card-img-top rounded-circle img-cover" alt="Job Description" /> */}
                  <div className="ratio ratio-1x1 rounded-circle overflow-hidden p-2">
                    <img
                      src="/jd.png"
                      className="card-img-top"
                      style={{ padding: "45px" }}
                      alt="Job Description"
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Job Description</h5>
                    <p className="card-text">
                      You can maange your company JD department wise.
                    </p>
                  </div>
                </div>

                <div className="card">
                  <div className="ratio ratio-1x1 rounded-circle overflow-hidden p-2">
                    <img
                      src="/hrm.png"
                      className="card-img-top"
                      style={{ padding: "45px" }}
                      alt="Jobs"
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Jobs</h5>
                    <p className="card-text">
                      Post & share your job vacancy over the internet
                    </p>
                  </div>
                </div>

                <div className="card">
                  <div className="ratio ratio-1x1 rounded-circle overflow-hidden p-2">
                    <img
                      src="/employee.png"
                      className="card-img-top"
                      style={{ padding: "45px" }}
                      alt="Candidate"
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Candidate</h5>
                    <p className="card-text">
                      Manage and track your pre-employee data for future
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <div className="card-group">
                <div className="card">
                  {/* <img src="/jd.png" className="card-img-top rounded-circle img-cover" alt="Job Description" /> */}
                  <div className="ratio ratio-1x1 rounded-circle overflow-hidden p-2">
                    <img
                      src="/employee.png"
                      className="card-img-top"
                      style={{ padding: "45px" }}
                      alt="Job Description"
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Employee PDS</h5>
                    <p className="card-text">
                      Easily manage your Employee's Personal Data Sheet.
                    </p>
                  </div>
                </div>

                <div className="card">
                  <div className="ratio ratio-1x1 rounded-circle overflow-hidden p-2">
                    <img
                      src="/appraisal.png"
                      className="card-img-top"
                      style={{ padding: "45px" }}
                      alt="Appraisal"
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title">Appraisal</h5>
                    <p className="card-text">
                      Evaluate your employee month and/or yearly
                    </p>
                  </div>
                </div>

                <div className="card">
                  <div className="ratio ratio-1x1 rounded-circle overflow-hidden p-2">
                    <img
                      src="/project.png"
                      className="card-img-top"
                      style={{ padding: "45px" }}
                      alt="Project Management"
                    />
                  </div>
                  <div className="card-body">
                    <h5 className="card-title" title="Project Management">
                      Project Manag.
                    </h5>
                    <p className="card-text">
                      Manage your project timesheets and activities
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-md-6">
            <div className="custom-div home-div div">
              <p className="item-title">Point of Sale - PoS</p>
              <p className="item-desc">Sale, Purchase, Inventory</p>
              <Button
                className="item-btn"
                onClick={() => gotoConsole("pos")}
                color="info"
              >
                {isAuthenticated ? "Goto Console" : "Buy Now"}
              </Button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="custom-div home-div">
              <p className="item-title">Human Resource Management - HRM</p>
              <Button
                className="item-btn"
                onClick={() => gotoConsole("hrm")}
                color="info"
              >
                {isAuthenticated ? "Goto Console" : "Buy Now"}
              </Button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="custom-div home-div">
              <p className="item-title">Account System Management - ASM</p>
              <Button
                className="item-btn"
                onClick={() => gotoConsole("asm")}
                color="info"
              >
                {isAuthenticated ? "Goto Console" : "Buy Now"}
              </Button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="custom-div home-div">
              <p className="item-title">File Management System</p>
              <Button
                className="block text-center item-btn"
                onClick={() => gotoConsole("file")}
                color="info"
              >
                {isAuthenticated ? "Goto Console" : "Buy Now"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
