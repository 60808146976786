import { ISizes } from '@interface/common.interface'
import { DECIMAL_NUMERIC_REGEX } from '@utility/checkvalidUrl'
import React, { ReactNode, useState } from 'react'
import Icon from '../Icon'

type IInputProps = {
  name?: string
  size?: ISizes
  variant?: 'solid' | 'outline'
  label?: string | ReactNode
  autoFocus?: boolean
  accept?: string
  type?:
  | 'button'
  | 'text'
  | 'color'
  | 'date'
  | 'email'
  | 'datetime-local'
  | 'month'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'time'
  | 'url'
  | 'week'
  | 'file'
  numaricOnly?: boolean
  isRequired?: boolean
  hasInfo?: boolean
  infoText?: string
  placeholder?: string | ReactNode
  defaultValue?: string | number | Date
  value?: string | number | Date
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
  onClick?: (event: any) => void
  onBlur?: (event: any) => void
  max?: string | number
  min?: string | number
  isError?: boolean
  helpText?: string
  isValid?: boolean
  errorMessage?: string
  noMargin?: boolean
  registerProperty?: any
  disabled?: boolean
  startIcon?: any
  endIcon?: any
  className?: string
  readOnly?: boolean
}

const Input = ({
  name,
  size = 'sm',
  autoFocus,
  variant = 'outline',
  accept,
  label,
  type = 'text',
  numaricOnly,
  placeholder,
  defaultValue,
  value,
  isRequired,
  onChange,
  onFocus,
  onClick,
  onBlur,
  min,
  max,
  hasInfo,
  infoText,
  isError,
  helpText,
  isValid,
  startIcon,
  endIcon,
  errorMessage,
  noMargin,
  registerProperty,
  disabled = false,
  className,
  readOnly,
}: IInputProps) => {
  const [inputType, setInputType] = useState<string>(type)

  return (
    <div className={`w-100 fv-row ${className || ''} ${noMargin ? '' : 'mb-3'}`}>
      {label ? (
        <label className='d-flex align-items-center fs-5 mb-2'>
          <span className={isRequired ? 'required' : ''}>{label}</span>
          {hasInfo && <Icon icon='help' hoverTitle={infoText} />}
        </label>
      ) : null}
      <div className='position-relative'>
        {startIcon && (
          <span className='position-absolute top-50 ms-4 mt-1 translate-middle-y'>{startIcon}</span>
        )}

        <input
          type={inputType}
          autoFocus={autoFocus}
          className={`form-control form-control-${size} form-control-${variant} ${isError ? 'is-invalid' : ''
            } ${isValid ? 'is-valid' : ''} ${startIcon ? 'px-11' : ''}`}
          name={name}
          accept={accept}
          required={isRequired}
          defaultValue={defaultValue}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onClick={onClick}
          onBlurCapture={onBlur}
          min={min}
          max={max}
          disabled={disabled}
          readOnly={readOnly}
          onKeyPress={
            type === 'number'
              ? (e) =>
                numaricOnly
                  ? !(e.charCode >= 48 && e.charCode <= 57) && e.preventDefault()
                  : !DECIMAL_NUMERIC_REGEX.test(e.key) && e.preventDefault()
              : null
          }
          onWheel={(e: any) => e.target.blur()}
          {...registerProperty}
        />

        {/* {type === 'password' && (
          <span className='position-absolute top-50 end-0 mt-1 me-3 translate-middle-y'>
            <Icon
              icon={inputType === 'password' ? 'visibility_off' : 'visibility'}
              variants='outlined'
              size={20}
              onClick={() => setInputType((prev) => (prev === 'password' ? 'text' : 'password'))}
            />
          </span>
        )} */}

        {!!endIcon && (
          <span className='position-absolute top-50 end-0 mt-1 me-3 translate-middle-y'>
            {endIcon}
          </span>
        )}
      </div>
      {isError ? (
        <div className='invalid-feedback d-block'>{errorMessage}</div>
      ) : !!helpText ? (
        <div className='form-text text-gray-600'>{helpText}</div>
      ) : null}
    </div>
  )
}

export { Input }

