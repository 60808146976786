import UserInfopreloader from "@components/loader2/UserInfopreloader";
import { USER_TOKEN } from "@constants/auth.constant";
import { IUserInfo } from "@interface/auth.interface";
import { AuthService } from "@services/api/Auth.service";
import * as authHelper from "@services/helper/auth.helper";
import { LocalStorageService } from "@services/utils/localStorage.service";
import { ENV } from "config/ENV.config";
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

// import { isExpiredToken } from "utility/jwt-token";

type AuthContextProps = {
  isAuthenticated: boolean;
  saveAuth: (token: string | undefined, uInfo: IUserInfo | undefined) => void;
  currentUser: IUserInfo | undefined;
  setCurrentUser: Dispatch<SetStateAction<IUserInfo | undefined>>;
  logout: () => void;
};

// const isValidToken = () => {
// 	const userToken: string = LocalStorageService.get(USER_TOKEN);
// 	if (!userToken) return false;
// 	console.log(!isExpiredToken(userToken));

// 	return !isExpiredToken(userToken);
// };

const initAuthContextPropsState = {
  isAuthenticated: false,
  saveAuth: () => { },
  currentUser: authHelper.getAuth()?.userInfo,
  setCurrentUser: () => { },
  logout: () => { },
};

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState);

const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [isChecking, setIsChecking] = useState<boolean>(true);
  const [currentUser, setCurrentUser] = useState<IUserInfo | undefined>(authHelper.getAuth()?.userInfo);
  const [isAuthenticated, makeAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const userToken: string = LocalStorageService.get(USER_TOKEN);
    if (userToken) {
      const appCode = ENV.appCode
      AuthService.verifyToken(appCode)
        .then((resp) => makeAuthenticated(true))
        .catch(() => logout())
        .finally(() => setIsChecking(false));
    } else setIsChecking(false);
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  // 	const interval = setInterval(() => {
  // 		const isValid = isValidToken();
  // 		if (!isValid) {
  // 			makeAuthenticated(isValid);
  // 			logout();
  // 		}
  // 	}, 10000);
  // 	return () => clearInterval(interval);
  // 	// eslint-disable-next-line
  // }, []);

  const saveAuth = (token: string | undefined, uInfo: IUserInfo | undefined) => {
    setCurrentUser(uInfo);
    if (token) {
      authHelper.setAuth(token, uInfo);
      makeAuthenticated(true);
    } else {
      authHelper.removeAuth();
      makeAuthenticated(false);
    }
  };

  const logout = () => {
    saveAuth(undefined, undefined);
  };

  if (isChecking) return <UserInfopreloader />;

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuth };
