import Button from "@components/Button";
import { Input } from "@components/Input";
import { FORGETPASSWORD, HOME, LOGIN } from "@constants/internal-route.constant";
import { AuthService } from "@services/api/Auth.service";
import { ToastService } from "@services/utils/toast.service";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

export default function ResetPassword() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const onPasswordSubmit = (formData) => {
    setIsLoading(true);
    AuthService.resetPassword(formData, location?.state?.token)
      .then((res) => {
        console.log("res", res);
        if (res?.code == 200) {
          ToastService.success(res?.msg);
          navigate(LOGIN);
        }
      })
      .catch((err) => {
        console.log("err", err);
        navigate(HOME);
        return ToastService.error(err?.msg);
      })
      .finally(() => setIsLoading(true));
  };

  useEffect(() => {
    if (!location?.state?.token) {
      navigate(FORGETPASSWORD)
    }
  }, []);

  return (
    <>
      <div className="feature-area">
        {/* <img src={forgetImg} alt="" height={100} /> */}
        <h4>Enter your New Password</h4>
      </div>
      <form onSubmit={handleSubmit(onPasswordSubmit)} className="reset-password">
        <Input
          type="password"
          label="Enter new password"
          isRequired
          registerProperty={{
            ...register("newPassword", { required: "Please enter new password" }),
          }}
          isError={!!errors?.newPassword}
          errorMessage={errors?.newPassword?.message as string}
        />
        <Input
          type="password"
          label="Confirm password"
          isRequired
          registerProperty={{
            ...register("cnfPassword", { required: "Please enter Confirm password" }),
          }}
          isError={!!errors?.cnfPassword}
          errorMessage={errors?.cnfPassword?.message as string}
        />
        <div>
          <Button
            className="w-100"
            color="primary"
            type="submit"
            size="lg"
            isLoading={isLoading}
            loadingText="Reseting..."
          >
            Reset Password
          </Button>
        </div>
      </form>
    </>
  );
}
