import Spinner from '../Loader/Spinner'
import React from 'react'
import {IColors, IObject, ISizes} from '@interface/common.interface'
type IButtonProps = {
  children: string | JSX.Element | JSX.Element[] | any
  color?: IColors
  size?: ISizes
  variant?: 'fill' | 'outline' | 'light'
  type?: 'submit' | 'button' | 'reset'
  isDisabled?: boolean
  className?: string
  onClick?: () => void
  isLoading?: boolean
  loadingText?: string
  dropClose?: boolean
  form?: string
  style?: IObject
}

const Button = ({
  children,
  color,
  size = 'sm',
  variant,
  type = 'button',
  isDisabled,
  className,
  onClick,
  isLoading,
  loadingText,
  dropClose,
  form,
  style,
}: IButtonProps) => {
  return (
    <button
      type={type}
      className={`d-flex align-items-center justify-content-center gap-1 btn btn-${size} btn${
        variant ? '-' + variant : ''
      }${color ? '-' + color : ''} ${className || ''}`}
      style={{...style}}
      disabled={isDisabled || isLoading}
      onClick={onClick}
      data-kt-menu-dismiss={dropClose ? 'true' : 'false'}
      form={form}
    >
      {isLoading ? <Spinner text={loadingText || 'Processing ...'} size='sm' /> : children}
    </button>
  )
}

export default Button
