import { COMPANY_ID, USER_INFO, USER_TOKEN } from "@constants/auth.constant";
import { IUserInfo } from "@interface/auth.interface";
import { LocalStorageService } from "@services/utils/localStorage.service";

const getAuth = (): {
	userToken: string | undefined;
	userInfo: IUserInfo | undefined;
} => {
	if (!localStorage) {
		return;
	}

	const userToken: string = LocalStorageService.get(USER_TOKEN);
	const lsUser: IUserInfo = LocalStorageService.get(USER_INFO);
	if (!userToken || !lsUser) return;

	try {
		const userInfo: IUserInfo = lsUser as IUserInfo;
		if (userToken && userInfo) return { userToken, userInfo };
	} catch (error) {
		console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
	}
};

const setAuth = (userToken: string, userInfo: IUserInfo) => {
	try {
		LocalStorageService.set(USER_TOKEN, userToken);
		LocalStorageService.set(USER_INFO, userInfo);
	} catch (error) {
		console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
	}
};

const removeAuth = () => {
	if (!localStorage) return;

	try {
		localStorage.removeItem(USER_TOKEN);
		localStorage.removeItem(USER_INFO);
		localStorage.removeItem(COMPANY_ID);
	} catch (error) {
		console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
	}
};

export { getAuth, removeAuth, setAuth };

