import {
	APP_LOGIN,
	FORGETPASSWORD,
	LOGIN,
	PRICING,
	RESET_PASSWORD,
	SIGNUP,
	VERIFY_OTP,
} from '@constants/internal-route.constant';
import { useAuth } from '@context/Auth';
import AuthLayout from '@layout/AuthLayout';
import PricingLayout from '@layout/PricingLayout';
import { setWindowTitle } from '@services/helper/common.helper';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppLogin from 'pages/AppLogin';
import ForgetPassword from 'pages/ForgetPassword';
import Home from 'pages/Home';
import ResetPassword from 'pages/ResetPassword';
import VerifyOtp from 'pages/VerifyOtp';
import Pricing from 'pages/pricing';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { PrivateRoutes } from 'routes/PrivateRoutes';
import Login from './pages/Login';
import SignUp from './pages/Signup';

// const AuthRoute = ({ children }) => {
//   const { isAuthenticated } = useAuth();
//   if (isAuthenticated) return <Navigate to={HOME} />;
//   return children;
// };

const App = () => {
	setWindowTitle();
	const { pathname } = window.location;
	const { isAuthenticated } = useAuth();

	return (
		<BrowserRouter>
			<Routes>
				<Route element={<AuthLayout />}>
					<Route
						path={APP_LOGIN}
						element={<AppLogin />}
					/>
					<Route
						index
						element={<Home />}
					/>
					{!isAuthenticated && (
						<>
							<Route
								path={LOGIN}
								element={<Login />}
							/>
							<Route
								path={SIGNUP}
								element={<SignUp />}
							/>
							<Route
								path={FORGETPASSWORD}
								element={<ForgetPassword />}
							/>
							<Route
								path={VERIFY_OTP}
								element={<VerifyOtp />}
							/>
							<Route
								path={RESET_PASSWORD}
								element={<ResetPassword />}
							/>
							<Route
								path='*'
								element={
									<Navigate
										to={LOGIN}
										state={{ from: pathname }}
									/>
								}
							/>
						</>
					)}
				</Route>
				{isAuthenticated && (
					<Route
						path='*'
						element={<PrivateRoutes />}
					/>
				)}
				<Route element={<PricingLayout />}>
					<Route
						path={PRICING}
						element={<Pricing />}
					/>
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export default App;
