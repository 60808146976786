import { axiosIns } from "config/api.config";

export const AuthService = {
  login: async (loginPayload): Promise<any> => await axiosIns.post("/auth/login", loginPayload),
  sendVerificationLink: async (payload): Promise<any> => await axiosIns.post("/auth/resend-account-verify-link", payload),

  getCountries: async (): Promise<any> => await axiosIns.get("/master/public/get-countries"),
  getPackages: async (appKey: any): Promise<any> => await axiosIns.get("/master/public/package-list/" + appKey),

  getCompanyList: async (payload: any): Promise<any> => await axiosIns.post("/auth/user-companies", payload),

  verifyToken: async (appKey): Promise<any> => await axiosIns.get("/auth/verify-token" + '?appKey=' + appKey),

  signUp: async (payload): Promise<any> => await axiosIns.post("/auth/register", payload),

  appLogin: async (payload): Promise<any> => await axiosIns.post("/auth/app-login", payload),

  forgetPassword: async (payload): Promise<any> => await axiosIns.post("/auth/forget-password", payload),

  verifyOtp: async (payload, tempToken: string): Promise<any> =>
    await axiosIns.post("/auth/verify-forget-password-otp", payload, {
      headers: { token: tempToken },
    }),

  resetPassword: async (payload, tempToken: string): Promise<any> =>
    await axiosIns.post("/auth/reset-forget-password", payload, {
      headers: { token: tempToken },
    }),

  changePassword: async (payload, tempToken: string, user_id: string): Promise<any> =>
    await axiosIns.post("/auth/change-password", payload, {
      headers: { token: tempToken, uid: user_id },
    }),

  updateProfile: async (payload, tempToken: string): Promise<any> =>
    await axiosIns.post("/auth/update-profile", payload, {
      headers: { token: tempToken },
    }),

  getProfile: async (tempToken: string): Promise<any> =>
    await axiosIns.get("/auth/get-user-profile", {
      headers: { token: tempToken },
    }),

  getCompanyProfile: async (tempToken: string): Promise<any> =>
    await axiosIns.get("/company/detail", {
      headers: { token: tempToken },
    }),

  updateCompanyProfile: async (payload: any): Promise<any> =>
    await axiosIns.put("/company/edit", payload),

  updateCompanyPackage: async (payload: any): Promise<any> =>
    await axiosIns.post("/company/package-upgrade", payload),

};
