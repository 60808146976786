interface IENV {
	appCode?: string;
	productCode?: string;
	envType?: string;
	apiEndpoint?: string;
	appName?: string;
}

export const ENV: IENV = {
	appCode: process.env.REACT_APP_CODE || '',
	productCode: process.env.REACT_APP_PRODUCT_CODE || '',
	appName: process.env.REACT_APP_NAME || "",
	envType: process.env.REACT_APP_ENV_TYPE || "",
	apiEndpoint: process.env.REACT_APP_GETWAY || "",
};
