import { AiFillPhone, AiOutlineTwitter } from "react-icons/ai";
import { BsFacebook, BsLinkedin, BsWhatsapp } from "react-icons/bs";
import { FaAddressCard } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <div className="footer-wrap">
      <div className="container">
        <div className="footer-mid">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <h5>Features</h5>
              <ul>
                <li>POS</li>
                <li>Purchase</li>
                <li>Sales</li>
                <li>Inventory</li>
                <li>HRM</li>
                <li>Account</li>
                <li>eFile</li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <h5>Solutions</h5>
              <ul>
                <li>Travel Agent Management</li>
                <li>Enterprise Resource Planning (ERP)</li>
                <li>Office Resource Planning (ORP)</li>
                <li>Business Intelligence (BI)</li>
                <li>Business Automation</li>
                <li>PKI - Public Key Infrastructure</li>
                <li>EJBCA</li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <h5>Service</h5>
              <ul>
                <li>Web Developement</li>
                <li>Apps Developement</li>
                <li>Digital Merketing </li>
                <li>Consultancy</li>
                <li>Domain, Hosting & Server</li>
                <li>Cyber Security</li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <h5>Contacts</h5>
              <div className="address-wrap">
                <div className="address-wrap_item">
                  <div className="footer-top_icon justify-content-between w-50">
                    <BsFacebook className="social-icon" />
                    <BsLinkedin className="social-icon" />
                    <AiOutlineTwitter className="social-icon" />
                    <BsWhatsapp className="social-icon" />
                  </div>
                </div>
                <div className="address-wrap_item">
                  <FaAddressCard className="add-icon" /> &nbsp;
                  <p>Dhaka, Bangladesh</p>
                </div>

                <div className="address-wrap_item">
                  <MdEmail className="add-icon" /> &nbsp;<p>support@hrboi.com </p>
                </div>
                <div className="address-wrap_item">
                  <AiFillPhone className="add-icon" />
                  &nbsp; <p>+8801 841 841 443 </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>Copyright © {year}. All rights reserved</p>
        </div>
      </div>
    </div>
  );
}
