import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export const ToastService = {
	success: (message: string): any =>
		toast.success(message || "No message!", { position: "top-right" }),
	error: (message: string): any =>
		toast.error(message || "No message!", {
			position: "top-right",
		}),
	info: (message: string): any =>
		toast.info(message || "No message!", { position: "top-right" }),
	warning: (message: string): any =>
		toast.warn(message || "No message!", { position: "top-right" }),
	loading: (message: string): any =>
		toast.loading(message || "Loading...", { position: "top-right" }),
};
