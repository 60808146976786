

const UserInfopreloader = () => {
	return (
		<div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
			<h4>
				Checking user information...
			</h4>
		</div>
	);
};

export default UserInfopreloader;
