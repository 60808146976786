import { IObject, ISizes } from '@interface/common.interface';
import React from 'react';

type ISelect = {
	name?: string;
	label?: string;
	size?: ISizes;
	valueKey?: string;
	textKey?: string;
	textKeySecond?: string;
	textKeyThird?: string;
	onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
	renderItem?: (op: IObject) => void;
	value?: string | number;
	defaultValue?: string | number;
	noMargin?: boolean;
	options: IObject[];
	isRequired?: boolean;
	isError?: boolean;
	hasInfo?: boolean;
	placeholder?: string;
	infoText?: string;
	errorMessage?: string;
	acceptNull?: boolean;
	registerProperty?: any;
	width?: number;
	isDisabled?: boolean;
	linkPage?: any;
};

const Select = ({
	name,
	label,
	size = 'sm',
	valueKey,
	textKey,
	textKeySecond,
	textKeyThird,
	onChange,
	renderItem,
	value,
	defaultValue,
	isRequired,
	noMargin,
	options,
	placeholder,
	hasInfo,
	infoText,
	isError,
	errorMessage,
	acceptNull = true,
	registerProperty,
	width,
	isDisabled = false,
	linkPage,
}: ISelect) => {
	return (
		<div className={`${width ? `w-${width}px` : ''} ${noMargin ? '' : 'mb-3'}`}>
			{label && (
				<label className='d-flex align-items-center fs-5 mb-2'>
					<span className={isRequired ? 'required' : ''}>{label}</span>
					{hasInfo && (
						<i
							className='fas fa-exclamation-circle ms-2 fs-7'
							data-bs-toggle='tooltip'
							title={infoText}
						/>
					)}
					{linkPage && (
						<span className='btn btn-sm btn-secondary'>
							<i className='fas fa-plus' />
						</span>
					)}
				</label>
			)}

			<select
				className={`form-select form-select-${size} ${isError ? 'is-invalid' : ''}`}
				data-allow-clear='true'
				required={isRequired}
				name={name}
				value={value}
				defaultValue={defaultValue}
				onChange={onChange}
				disabled={isDisabled}
				{...registerProperty}
			>
				{acceptNull && <option value=''>{placeholder || 'Choose one'}</option>}
				{options?.map((val, idx) => (
					<option
						key={idx}
						value={valueKey ? val?.[valueKey] : JSON.stringify(val)}
					>
						{renderItem ? renderItem(val) : val?.[textKey as string]}
						{textKeySecond && val?.[textKeySecond] ? ' - ' + val?.[textKeySecond] : ''}
						{textKeyThird && val?.[textKeyThird] ? ' - ' + val?.[textKeyThird] : ''}
					</option>
				))}
			</select>
			{isError && <div className='invalid-feedback d-block'>{errorMessage}</div>}
		</div>
	);
};

export { Select };
