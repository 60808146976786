import Footer from "@components/Footer/Footer";
import Header from "pages/Header";
import { Outlet } from "react-router-dom";

const PricingLayout = () => (
  <div>
    <Header />

    <div className="container container-wrap">
      <div className="row">
        <div className="col-md-12">
          <div className="cust-card ">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
);
export default PricingLayout;
