import Button from "@components/Button";
import { Input } from "@components/Input";
import { ButtonLoader } from "@components/loader2";
import { FORGETPASSWORD, RESET_PASSWORD } from "@constants/internal-route.constant";
import { AuthService } from "@services/api/Auth.service";
import { ToastService } from "@services/utils/toast.service";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import forgetImg from "../assets/image/forget-password.png";

export default function VerifyOtp() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();

  const {
    state: { token },
  } = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!location?.state?.token) {
      navigate(FORGETPASSWORD)
    }
  }, []);

  const navigate = useNavigate();

  const onSubmit = (formData) => {
    setIsLoading(true);
    AuthService.verifyOtp(formData, token)
      .then((res) => {
        console.log("verify res", res);
        navigate(RESET_PASSWORD, {
          state: { token: res.body?.tempToken },
        });
      })
      .catch((err) => ToastService.error(err?.msg))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="forget-password-wrap">
      <div className="feature-area">
        <img src={forgetImg} alt="" style={{ "width": "100%" }} />
        <h4>Verify OTP</h4>
        <p>Enter your OTP from Email or Mobile number(Valid time 5 minutes).</p>
      </div>
      <form className="form-area" onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="text"
          label="Enter your OTP"
          isRequired
          registerProperty={{
            ...register("otp", { required: "Please enter OTP code" }),
          }}
          isError={!!errors?.otp}
          errorMessage={errors?.otp?.message as string}
        />
        <Button className="btn btn-primary" color="primary" type="submit" isDisabled={isLoading}>
          {isLoading ? <ButtonLoader /> : "Verify OTP"}
        </Button>
      </form>
    </div>
  );
}
